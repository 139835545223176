import React from 'react';

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { HiSearch } from 'react-icons/hi';

import { useAppDispatch, useAppSelector } from '@hooks/store';
import { setFindMentorFilters } from '@modules/find-mentor/slice';

const SearchBox = () => {
    const dispatch = useAppDispatch();
    const { query } = useAppSelector(state => state.reducer.mentorFilters);
    const handleSearch = (value: string) => {
        dispatch(
            setFindMentorFilters({
                query: value.trim(),
            })
        );
    };

    return (
        <InputGroup width={{ base: 210, sm: '100%', md: 'auto' }}>
            <InputLeftElement pointerEvents="none">
                <HiSearch color="#4c50ea" fontSize={18} />
            </InputLeftElement>
            <Input
                value={query}
                type="text"
                placeholder="Search by company, language or role"
                border="none"
                w={{ base: 'auto', sm: 'auto', md: 330 }}
                onChange={e => handleSearch(e.target.value)}
                color="mainText"
                _focusVisible={{
                    boxShadow: 'none',
                }}
            />
        </InputGroup>
    );
};

export default SearchBox;
