'use client';

import React from 'react';

import { Flex } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '@hooks/store';
import { setFindMentorFilters } from '@modules/find-mentor/slice';

import AppliedFilterBadge from './AppliedFilterBadge';

const AppliedExpertise = () => {
    const { expertise } = useAppSelector(state => state.reducer.mentorFilters);
    const dispatch = useAppDispatch();
    const onRemoveExpertise = (value: string) => {
        const updatedExpertise = expertise.filter(item => item !== value);
        dispatch(
            setFindMentorFilters({
                expertise: updatedExpertise,
            })
        );
    };
    return (
        <>
            {expertise?.length > 0 && (
                <Flex flexDir="row" gap={2} width="100%" flexWrap="wrap">
                    {expertise.map(item => (
                        <AppliedFilterBadge
                            key={item}
                            text={item}
                            onClose={() => onRemoveExpertise(item)}
                        />
                    ))}
                </Flex>
            )}
        </>
    );
};

export default AppliedExpertise;
