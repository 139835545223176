const ROOTS = {
    MENTOR: '/mentor',
    MENTEE: '/mentee',
};

export const paths = {
    mentor: {
        auth: {
            login: `${ROOTS.MENTOR}/login`,
            signup: `${ROOTS.MENTOR}/register`,
            verifyEmail: `${ROOTS.MENTOR}/verify-email`,
            resetPassword: `${ROOTS.MENTOR}/reset-password`,
            forgotPassword: `${ROOTS.MENTOR}/forgot-password`,
        },
        dashboard: {
            home: `${ROOTS.MENTOR}/dashboard`,
            profile: `${ROOTS.MENTOR}/profile`,
            bookings: `${ROOTS.MENTOR}/bookings`,
            availability: `${ROOTS.MENTOR}/availability`,
            payouts: `${ROOTS.MENTOR}/payouts`,
            settings: `${ROOTS.MENTOR}/settings`,
            notifications: `${ROOTS.MENTOR}/notifications`,
            helpAndSupport: `${ROOTS.MENTOR}/help-support`,
        },
    },
    mentee: {
        auth: {
            login: `${ROOTS.MENTEE}/login`,
            signup: `${ROOTS.MENTEE}/register`,
            verifyEmail: `${ROOTS.MENTEE}/verify-email`,
            resetPassword: `${ROOTS.MENTEE}/reset-password`,
            forgotPassword: `${ROOTS.MENTEE}/forgot-password`,
        },
        dashboard: {
            home: `${ROOTS.MENTEE}/dashboard`,
            profile: `${ROOTS.MENTEE}/profile`,
            bookings: `${ROOTS.MENTEE}/bookings`,
            billings: `${ROOTS.MENTEE}/billings`,
            settings: `${ROOTS.MENTEE}/settings`,
            notifications: `${ROOTS.MENTEE}/notifications`,
            helpAndSupport: `${ROOTS.MENTEE}/help-support`,
        },
    },
    general: {
        home: '/',
        findMentor: '/find-mentor',
        bookSession: '/find-mentor/[slug]',
        contact: '/contact',
        pricing: '/pricing',
        billing: '/pricing/billing',
        howItWorks: '/how-it-works',
        premeeting: '/pre-meeting',
        meeting: '/meeting',
        privacy: '/privacy-policy',
        termsandconditions: '/terms-and-conditions',
    },
};
