import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SuccessSlice {
    imageUrl: string | null;
    mentorName: string;
    service: string | null;
    fromDate: string;
    toDate: string;
    showModel: boolean;
}

const initialState: SuccessSlice = {
    imageUrl: '',
    mentorName: '',
    service: '',
    fromDate: '',
    toDate: '',
    showModel: false,
};

export const SessionSucessSlice = createSlice({
    name: 'sessionSucessSlice',
    initialState,
    reducers: {
        addBookSessionData: (state, action: PayloadAction<Partial<SuccessSlice>>) => {
            state = { ...state, ...action.payload };
            return state;
        },
        hideBookingSuccessModal: state => {
            state.showModel = false;
        },
    },
});

export const { addBookSessionData, hideBookingSuccessModal } = SessionSucessSlice.actions;
export default SessionSucessSlice.reducer;
