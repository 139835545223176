/* eslint-disable no-nested-ternary */
import { Box } from '@chakra-ui/react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

type RatingProps = {
    rating: number;
};

type RatingStarsProps = {
    value: number;
    rating: number;
};
const RatingStars = ({ value, rating }: RatingStarsProps) => (
    <Box as="span">
        {rating >= value ? (
            <FaStar fontSize={14} color="#ECC94B" />
        ) : rating >= value - 0.5 ? (
            <FaStarHalfAlt fontSize={14} color="#ECC94B" />
        ) : (
            <FaRegStar fontSize={14} color="#ECC94B" />
        )}
    </Box>
);
const Rating = ({ rating }: RatingProps) => (
    <Box width="100%" display="flex" gap={1} justifyContent="end">
        <RatingStars value={1} rating={rating} />
        <RatingStars value={2} rating={rating} />
        <RatingStars value={3} rating={rating} />
        <RatingStars value={4} rating={rating} />
        <RatingStars value={5} rating={rating} />
    </Box>
);

export default Rating;
