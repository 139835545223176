import React from 'react';

import { VStack, Heading, Box } from '@chakra-ui/react';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';

import { CustomText } from '@components/Custom-text';

type MentorIconProps = {
    imageSrc: string | StaticImport;
    heading: string;
    text: string;
    isSingleLine?: boolean;
};
export const MenterInfoCard: React.FC<MentorIconProps> = ({
    imageSrc,
    heading,
    text,
    isSingleLine = false,
}) => (
    <VStack width={280} align="center" justify="flex-start" px={4}>
        <Image src={imageSrc} alt="img" width={44} />
        <Heading size="md">{heading}</Heading>
        <CustomText color="shadowslate" textAlign="center" size="md">
            {text}
        </CustomText>
        {isSingleLine && (
            <Box visibility="hidden">
                <CustomText color="shadowslate" textAlign="center" size="md">
                    {text}
                </CustomText>
            </Box>
        )}
    </VStack>
);
