import React from 'react';

import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { SlArrowDown } from 'react-icons/sl';

import { CustomText } from '@components/Custom-text';
import Expertise from '@modules/find-mentor/components/sections/Expertise';

const ExpertiseDropdown = () => (
    <Menu>
        <MenuButton
            px={{ base: 0, sm: 0, md: 2 }}
            as={Button}
            rightIcon={<SlArrowDown />}
            style={{
                backgroundColor: 'transparent',
                marginTop: '2px',
            }}
        >
            <CustomText color="shadowSlate"> Expertise </CustomText>
        </MenuButton>
        <MenuList border="none" py={0} backgroundColor="white">
            <Expertise />
        </MenuList>
    </Menu>
);

export default ExpertiseDropdown;
