import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface RTCToken {
    rtcToken: string;
}
const initialState: RTCToken = {
    rtcToken: '',
};

export const agoraSlice = createSlice({
    name: 'agora',
    initialState,
    reducers: {
        setAgoraToken: (state, action: PayloadAction<Partial<RTCToken>>) => {
            state = { ...state, ...action.payload };
            return state;
        },
        clearToken: state => {
            state = initialState;
            return state;
        },
    },
});

export const { setAgoraToken, clearToken } = agoraSlice.actions;

export default agoraSlice.reducer;
