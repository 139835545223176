'use client';

import React from 'react';

import { Box, CloseButton } from '@chakra-ui/react';

import { CustomText } from '@components/Custom-text';

type AppliedFilterBadgeProps = {
    onClose: () => void;
    text: string;
};

const AppliedFilterBadge = ({ onClose, text }: AppliedFilterBadgeProps) => (
    <Box
        pl={3}
        justifyContent="space-between"
        borderColor="border"
        borderWidth={1}
        alignItems="center"
        borderRadius={5}
        display="flex"
    >
        <CustomText color="mainText">{text}</CustomText>
        <CloseButton size="sm" onClick={onClose} />
    </Box>
);

export default AppliedFilterBadge;
