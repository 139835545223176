import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Mentee, Mentor } from '../types';

interface AuthState {
    accessToken: string;
    refreshToken: string;
    isAuthenticated?: boolean;
    user: Partial<Mentor | Mentee | null>;
    accessTokenExpiresIn: number;
    refreshTokenExpiresIn: number;
}

const initialState: AuthState = {
    accessToken: '',
    refreshToken: '',
    user: null,
    isAuthenticated: false,
    accessTokenExpiresIn: 0,
    refreshTokenExpiresIn: 0,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authSuccess: (state, action: PayloadAction<Partial<AuthState>>) => {
            state = { ...state, ...action.payload };
            return state;
        },
        setLogout: state => {
            state = {
                ...initialState,
                user: { username: state.user?.username, userType: state.user?.userType },
            };
            return state;
        },
    },
});

export const { authSuccess, setLogout } = authSlice.actions;

export default authSlice.reducer;
