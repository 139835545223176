import React, { useState } from 'react';

import { Tabs, Tab, Flex, TabList, TabIndicator, useBreakpointValue } from '@chakra-ui/react';

import { HowItWorksStepOne } from '@modules/how-it-works/component/stepone/HowItWorksStepOne';
import { newStepsData } from '@utils/data';

import { CustomTabs } from '../cards/CustomTabs';

type StepperTabProps = {
    number: string;
    heading: string;
    text: string;
    onClick: () => void;
    isMobile: boolean;
    activeTab?: number;
    tab?: number;
};

const StepperTab = ({
    heading,
    number,
    text,
    onClick,
    isMobile,
    activeTab,
    tab,
}: StepperTabProps) => (
    <Tab
        onClick={onClick}
        borderLeftWidth={isMobile ? 3 : 0}
        borderTopWidth={isMobile ? 0 : 3}
        borderColor="border"
    >
        <CustomTabs number={number} heading={heading} text={text} activeTab={activeTab} tab={tab} />
    </Tab>
);

type TabsListProps = {
    setTab: React.Dispatch<React.SetStateAction<number>>;
    isMobile: boolean;
    tab: number;
};

const TabsList = ({ setTab, isMobile, tab }: TabsListProps) => (
    <TabList>
        <StepperTab
            onClick={() => setTab(0)}
            heading="Login or Register"
            number="01"
            text="Follow the whole step and you’re just ready to get started."
            isMobile={isMobile}
            activeTab={0}
            tab={tab}
        />
        <StepperTab
            onClick={() => setTab(1)}
            heading="Fill Your Personal Data"
            number="02"
            text="Follow the whole step and you’re just ready to get started."
            isMobile={isMobile}
            activeTab={1}
            tab={tab}
        />
        <StepperTab
            onClick={() => setTab(2)}
            heading="Find The Mentor"
            number="03"
            text="Follow the whole step and you’re just ready to get started."
            isMobile={isMobile}
            activeTab={2}
            tab={tab}
        />
        <StepperTab
            onClick={() => setTab(3)}
            heading="Start The Sessions"
            number="04"
            text="Follow the whole step and you’re just ready to get started."
            isMobile={isMobile}
            activeTab={3}
            tab={tab}
        />
        <TabIndicator height="2px" width="2px" bg="blue.500" borderRadius="1px" />
    </TabList>
);

export default function HowItWorksStepper() {
    const [tab, setTab] = useState<number>(0);
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <>
            <HowItWorksStepOne
                stepNumber={newStepsData[tab].stepNumber}
                heading={newStepsData[tab].heading}
                backgroundImage={newStepsData[tab].backgroundImage}
                imageSrc={newStepsData[tab].imageSrc}
                text={newStepsData[tab].text}
                direction={{ base: 'column', md: 'row-reverse' }}
            />

            {isMobile ? (
                <Flex flexDir={{ base: 'column', sm: 'column', md: 'row' }}>
                    <Tabs variant="unstyled" orientation="vertical">
                        <TabsList setTab={setTab} isMobile={isMobile} tab={tab} />
                    </Tabs>
                </Flex>
            ) : (
                <Flex>
                    <Tabs variant="unstyled">
                        <TabsList setTab={setTab} isMobile={false} tab={tab} />
                    </Tabs>
                </Flex>
            )}
        </>
    );
}
