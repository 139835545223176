import React from 'react';

import { Heading, Box, HStack, Stack, Divider, ResponsiveValue } from '@chakra-ui/react';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';
import { FaCheckCircle } from 'react-icons/fa';

import { CustomText } from '@components/Custom-text';

type HowItWorksProps = {
    stepNumber: string;
    heading: string;
    text: string;
    imageSrc: string | StaticImport;
    backgroundImage: string;
    direction: ResponsiveValue<'row' | 'column' | 'row-reverse' | 'column-reverse'> | undefined;
};
export const HowItWorksStepOne = ({
    stepNumber,
    heading,
    text,
    imageSrc,
    backgroundImage,
    direction,
}: HowItWorksProps) => (
    <HStack
        py={{ base: 5, sm: 5, md: 14 }}
        px={{ base: 0, sm: 0, md: 14 }}
        borderWidth={1}
        borderColor="border"
        borderRadius={18}
        justifyContent="space-between"
        flexDirection={direction}
        mb={4}
    >
        <Stack spacing={2} alignItems="flex-start" maxWidth={{ base: '80%', md: '500px' }}>
            <Heading size="md" color="primary.dark">
                {' '}
                Step {stepNumber}
            </Heading>
            <Heading color="primary.light">{heading}</Heading>
            <CustomText color="shadowSlate;" size="lg">
                {text}
            </CustomText>
            <Box py={{ base: '1', md: '4' }}>
                <Divider orientation="horizontal" w={{ base: 300, md: 500 }} />
            </Box>

            <Box
                gap={2}
                display="flex"
                justifyItems="center"
                alignItems="center"
                color="primary.dark"
            >
                <FaCheckCircle />

                <CustomText color="primary.dark;" size="md">
                    Your personalized account to begin your journey.
                </CustomText>
            </Box>

            <Box
                gap={2}
                display="flex"
                justifyItems="center"
                alignItems="center"
                color="primary.dark"
            >
                <FaCheckCircle />
                <CustomText color="primary.dark;" size="md">
                    Holds your progress, preferences, and more.
                </CustomText>
            </Box>

            <Box
                gap={2}
                display="flex"
                justifyItems="center"
                alignItems="center"
                color="primary.dark"
            >
                <FaCheckCircle />
                <CustomText color="primary.dark;" size="md">
                    Your gateway to all app functionalities.
                </CustomText>
            </Box>
        </Stack>

        <HStack
            backgroundColor={backgroundImage}
            px={{ base: 2, md: 12 }}
            pt={{ base: 2, md: 12 }}
            borderRadius={10}
            w={{ base: '80%', md: '50%' }}
        >
            <Image src={imageSrc} alt="background image of howitworks" />
        </HStack>
    </HStack>
);
