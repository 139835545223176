import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { IGenericError } from '@data-types/generic';

const handleError = (error: FetchBaseQueryError) => {
    const data: IGenericError | unknown = error?.data;
    if (typeof data === 'object' && data !== null) {
        return data as IGenericError;
    }
    return { message: 'Something went wrong', error: '', statusCode: 0 };
};

const ApiResponses = {
    handleError,
};

export default ApiResponses;
