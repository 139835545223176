'use client';

import React from 'react';

import { Heading, Stack, Box, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import Image from 'next/image';

import backgroundImage from '@assets/backgroundimage.jpg';
import photos from '@assets/photos.png';
import underlineHome from '@assets/underlineHome.svg';
import { CustomText } from '@components/Custom-text';

import FindMentorDesktopView from './FindMentorDesktopView';
import FindMentorMobileView from './FindMentorMobileView';

const HomeTopSection = () => {
    const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });
    return (
        <Stack
            style={{
                backgroundImage: `url("${backgroundImage.src}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
            py={{ base: 4, md: 20 }}
            mr={{ base: 0, md: -10, lg: -20 }}
            direction={{ base: 'column-reverse', md: 'row' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            width="initial"
        >
            <Stack maxW="lg" spacing={6}>
                <Heading>
                    {' '}
                    Find Your{' '}
                    <Box as="span" position="relative">
                        <Box
                            as="span"
                            color={useColorModeValue('primary.light', 'primary.dark')}
                            position="relative"
                        >
                            Great Mentor{' '}
                            <Image
                                src={underlineHome}
                                style={{
                                    position: 'absolute',
                                    left: '0',
                                    bottom: '-2px',
                                    width: '200px',
                                }}
                                alt="underline home"
                            />
                        </Box>
                    </Box>
                    Here. Easy & Fast.
                </Heading>
                <CustomText color="shadowSlate" size="md">
                    Upskilling is hard and overwhelming, let YourYoda find you a mentor who can be a
                    lighthouse in your career journey.
                </CustomText>
                <>{isMobile ? <FindMentorMobileView /> : <FindMentorDesktopView />}</>
                <Box mt={-4}>
                    <CustomText color="shadowSlate">
                        Say hello to your career&apos;s rocket fuel.
                    </CustomText>
                </Box>
            </Stack>

            <Stack mr={{ base: 0, sm: 0, md: 0, lg: 20 }}>
                <Image priority src={photos} alt="mentors" />
            </Stack>
        </Stack>
    );
};
export default HomeTopSection;
