import React, { JSXElementConstructor, ReactElement } from 'react';

import { Button } from '@chakra-ui/react';

import { CustomText } from '@components/Custom-text';

interface LoadingButtonProps {
    type: 'button' | 'submit' | 'reset' | undefined;
    buttonLabel: string;
    isLoading: boolean;
    loadingText: string;
    isDisabled?: boolean;
    onClick?: () => void;
    width?: number | string;
    icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
    id?: string;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
    type,
    buttonLabel,
    isLoading,
    loadingText,
    onClick,
    id,
    icon,
    width = '100%',
    isDisabled,
}) => (
    <Button
        isDisabled={isDisabled}
        isLoading={isLoading}
        loadingText={loadingText}
        leftIcon={icon}
        spinnerPlacement="end"
        backgroundColor="primary.light"
        color="white"
        width={width}
        type={type}
        form={id}
        onClick={onClick}
        alignSelf="end"
        _hover={{ bgColor: 'primary.dark' }}
    >
        <CustomText size="md" color="white">
            {buttonLabel}
        </CustomText>
    </Button>
);

export default LoadingButton;
