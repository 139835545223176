import React from 'react';

import { Heading, Stack } from '@chakra-ui/react';

import { CustomText } from '@components/Custom-text';
import { newTextsData, textsData } from '@utils/data';

import { StalingCards } from '../cards/StalingCards';

export const StalingCard = () => (
    <Stack>
        <Stack spacing={6} flexDir={{ base: 'column', md: 'row' }} justifyContent="center">
            {textsData.map((text, index) => (
                <Stack
                    key={index}
                    gap={8}
                    align={{ base: 'flex-start', md: 'center' }}
                    justify="center"
                >
                    <StalingCards text={text} />
                </Stack>
            ))}
        </Stack>
        <Stack spacing={6} flexDir={{ base: 'column', md: 'row' }} justifyContent="center">
            {newTextsData.map((text, index) => (
                <Stack
                    key={index}
                    gap={8}
                    align={{ base: 'flex-start', md: 'center' }}
                    justify="center"
                >
                    <StalingCards text={text} />
                </Stack>
            ))}
        </Stack>

        <Stack py={{ base: 6, md: 20 }} maxW="592px" mx="auto" align="center" justify="center">
            <Heading>How It Works</Heading>
            <CustomText color="shadowSlate" textAlign="center">
                Book 1v1 mentorship sessions tailored to your schedule and needs. Connect with
                expert mentors, choose available slots, and start your journey to career growth
                today.
            </CustomText>
        </Stack>
    </Stack>
);
