import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ForgotPasswordState {
    isEmailSend: boolean;
}

const initialState: ForgotPasswordState = {
    isEmailSend: false,
};

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        emailSend: (state, action: PayloadAction<ForgotPasswordState>) => {
            state = { ...state, ...action.payload };
            return state;
        },
    },
});

export const { emailSend } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
