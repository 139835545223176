'use client';

/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';

import { Box, Stack, Heading, SimpleGrid } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';

import { PrimaryButton } from '@components/custom-buttons';
import { Loader } from '@components/custom-loader';
import { CustomText } from '@components/Custom-text';
import { useLazyGetMentorsQuery } from '@modules/find-mentor/api';
import MentorCard from '@modules/find-mentor/components/cards/MentorCard';
import { paths } from '@routes/path';

export const TopMentors = () => {
    const router = useRouter();
    const [getMentors, { data, isFetching }] = useLazyGetMentorsQuery();

    useEffect(() => {
        getMentors({
            offset: 0,
            limit: 5,
        });
    }, [getMentors]);

    const handleExploreAll = () => {
        router.push(paths.general.findMentor);
    };
    return (
        <Stack>
            <Stack py={8} maxW="592px" mx="auto">
                <Heading textAlign="center"> Discover the World's Top Mentors </Heading>
                <CustomText color="shadowSlate" textAlign="center">
                    Unlock your potential with our mentorship program, connecting you with expert
                    mentors to upskill and seamlessly transition into your dream career. Empower
                    your journey with personalized guidance and industry insights.
                </CustomText>
            </Stack>

            <Stack>
                {isFetching ? (
                    <Loader height="10vh" width="100%" size="lg" />
                ) : (
                    <SimpleGrid
                        columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        width="100%"
                        spacing="10px"
                    >
                        {data?.mentors &&
                            data.mentors?.length > 0 &&
                            data.mentors?.map((mentor, index) => (
                                <MentorCard key={`key ${index}`} mentorInfo={mentor} />
                            ))}
                    </SimpleGrid>
                )}

                <Box py={3} textAlign="center">
                    <PrimaryButton
                        buttonLabel="Explore All Mentors"
                        onClick={handleExploreAll}
                        type="button"
                    />
                </Box>
            </Stack>
        </Stack>
    );
};
