import { Button } from '@chakra-ui/react';

import { CustomText } from '@components/Custom-text';

interface CancelButtonProps {
    onClick: () => void;
}

const CancelButton = ({ onClick }: CancelButtonProps) => (
    <Button type="button" variant="outline" mr={3} onClick={onClick}>
        <CustomText size="md" color="mainText">
            Cancel
        </CustomText>
    </Button>
);

export default CancelButton;
