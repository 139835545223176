import React from 'react';

import { Stack } from '@chakra-ui/react';

import { CustomText } from '@components/Custom-text';

type TabdataProps = {
    number: string;
    heading: string;
    text: string;
    activeTab?: number;
    tab?: number;
};
export const CustomTabs: React.FC<TabdataProps> = ({ number, heading, text, activeTab, tab }) => (
    <Stack py={{ base: 4, md: 2 }}>
        <CustomText
            color={activeTab === tab ? 'primary.light' : 'titleText'}
            size="md"
            weight={500}
        >
            {number}
        </CustomText>
        <CustomText
            color={activeTab === tab ? 'primary.light' : 'titleText'}
            size="xl"
            weight={500}
        >
            {heading}
        </CustomText>
        <CustomText color="shadowSlate" weight={200}>
            {text}
        </CustomText>
    </Stack>
);
