import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface MentorFilterState {
    expertise: string[];
    country: string | undefined;
    totalExperience: number;
    totalRating: number;
    orderBy: string | undefined;
    query: string;
    fromDate: string;
    toDate: string;
}
const initialState: MentorFilterState = {
    expertise: [],
    country: undefined,
    totalExperience: 0,
    totalRating: 0,
    orderBy: '',
    query: '',
    fromDate: '',
    toDate: '',
};

export const mentorFiltersSlice = createSlice({
    name: 'mentorFilters',
    initialState,
    reducers: {
        setFindMentorFilters: (state, action: PayloadAction<Partial<MentorFilterState>>) => {
            state = { ...state, ...action.payload };
            return state;
        },
        clearFilters: state => {
            state = initialState;
            return state;
        },
    },
});

export const { setFindMentorFilters, clearFilters } = mentorFiltersSlice.actions;

export default mentorFiltersSlice.reducer;
