import React from 'react';

import { Button } from '@chakra-ui/react';

import { CustomText } from '@components/Custom-text';

interface PrimaryButtonProps {
    type: 'button' | 'submit' | 'reset' | undefined;
    buttonLabel: string;
    id?: string;
    onClick?: () => void;
    isLoading?: boolean;
    width?: string;
    height?: string;
    isDisabled?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
    type,
    buttonLabel,
    id,
    onClick,
    isLoading,
    width,
    height,
    isDisabled,
}) => (
    <Button
        width={width}
        height={height}
        backgroundColor="primary.light"
        color="white"
        isDisabled={isDisabled}
        isLoading={isLoading}
        type={type}
        _hover={{ bgColor: 'primary.dark' }}
        form={id}
        onClick={onClick}
    >
        <CustomText size="md" color="white">
            {buttonLabel}
        </CustomText>
    </Button>
);

export default PrimaryButton;
