import React from 'react';

import { Flex } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

import { CustomText } from '@components/Custom-text';

type StalingCardProps = {
    text: string;
};
export const StalingCards: React.FC<StalingCardProps> = ({ text }) => (
    <Flex
        gap={6}
        p={4}
        boxShadow="md"
        borderRadius="6"
        color="#4C50EA"
        alignItems="center"
        // justifyContent="center"
        width="100%"
    >
        <FaCheckCircle />

        <CustomText color="primary.dark" size="md">
            {' '}
            {text}
        </CustomText>
    </Flex>
);
