import React from 'react';

import { Divider, HStack, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';
import { GoPeople } from 'react-icons/go';

import { PrimaryButton } from '@components/custom-buttons';
import AppliedExpertise from '@modules/find-mentor/components/sections/AppliedExpertise';
import { paths } from '@routes/path';

import ExpertiseDropdown from './ExpertiseDropdown';
import SearchBox from './SearchBox';

const FindMentorMobileView = () => {
    const router = useRouter();

    const findMentor = () => {
        router.push(paths.general.findMentor);
    };

    return (
        <>
            <HStack gap={3} border="1px" color="border" w={650} h={16} borderRadius={8}>
                <SearchBox />
                <Divider orientation="vertical" height="10" />
                <Stack justifyContent="center" alignItems="center">
                    <GoPeople fontSize={26} />
                </Stack>
                <Stack color="shadowSlate">
                    <ExpertiseDropdown />
                </Stack>
                <PrimaryButton buttonLabel="Find Mentor" onClick={findMentor} type="button" />
            </HStack>
            <AppliedExpertise />
        </>
    );
};

export default FindMentorMobileView;
