import React from 'react';

import { Heading, Stack, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';

import { PrimaryButton } from '@components/custom-buttons';
import { CustomText } from '@components/Custom-text';
import { paths } from '@routes/path';

export default function Partner() {
    const router = useRouter();
    const handleMentor = () => {
        router.push(paths.mentor.auth.signup);
    };

    return (
        <Stack spacing={8}>
            <Flex
                justifyContent={{ base: 'center', sm: 'center', md: 'space-between' }}
                alignItems={{ base: 'center', sm: 'center' }}
                flexDir={{ base: 'column', md: 'row' }}
                gap={3}
            >
                <Heading
                    width={{ base: '100%', md: '797px' }}
                    textAlign={{ base: 'center', md: 'start' }}
                >
                    Share your expertise and shape the next generation of talent.
                </Heading>

                <PrimaryButton
                    width="fit-content"
                    buttonLabel="Become a Mentor"
                    onClick={handleMentor}
                    type="button"
                />
            </Flex>

            {/* <HStack
                gap={4}
                py={4}
                flexDir={{ base: 'column', md: 'row' }}
                justifyContent={{ base: 'center', md: 'space-evenly' }}
            >
                {partnerNewCardData.map((partnerCard, index) => (
                    <PartnerCard
                        key={index}
                        heading={partnerCard.heading}
                        text={partnerCard.text}
                        backgroundImage={partnerCard.backgroundImage}
                        imageSrc={partnerCard.imageSrc}
                    />
                ))}
            </HStack> */}
            <Stack py={8} maxW="592px" mx="auto" align="center">
                <Heading>What’s stalling your growth?</Heading>
                <CustomText color="shadowSlate" textAlign="center">
                    Feeling stuck in your career? Discover what&apos;s stalling your growth and
                    break through barriers with expert mentorship designed to propel you forward.
                </CustomText>
            </Stack>
        </Stack>
    );
}
