import React from 'react';

import { HStack, Stack, Heading, Box } from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';

import LeftMenteeImage from '@assets/Group 1.png';
import RightMenteeImage from '@assets/Group 2.png';
import BgGradient from '@assets/mesh-gradient.png';
import { PrimaryButton } from '@components/custom-buttons';
import { paths } from '@routes/path';

export default function BecomeMentee() {
    const router = useRouter();
    const handleMente = () => {
        router.push(paths.mentee.auth.signup);
    };
    return (
        <Box
            style={{
                backgroundImage: `url("${BgGradient.src}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
            my="10"
            borderRadius="20"
            px={5}
            py={0}
        >
            <HStack
                py={{ base: 4, md: 20 }}
                borderRadius="xl"
                flexDir={{ base: 'column', md: 'row' }}
            >
                <Image src={LeftMenteeImage} alt="leftimage" />
                <Stack
                    py={8}
                    spacing={8}
                    maxW={{ base: '300px', md: '592px' }}
                    mx="auto"
                    align="center"
                    justify="center"
                    textAlign="center"
                >
                    <Heading>Learn and Grow With Help From World-class Mentors</Heading>
                    <PrimaryButton
                        buttonLabel="Become a Mentee"
                        onClick={handleMente}
                        type="button"
                    />
                </Stack>
                <Box>
                    <Image src={RightMenteeImage} alt="right image" />
                </Box>
            </HStack>
        </Box>
    );
}
