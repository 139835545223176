'use client';

import React from 'react';

import HowItWorksStepper from '@modules/home/component/sections/HowItWorksStepper';

import Domains from '../sections/Domains';
import HomeTopSection from '../sections/HomeTopSection';
import LinearImages from '../sections/LinearImages';
import { MentorIcon } from '../sections/MentorIcon';
import Partner from '../sections/Partners';
import { StalingCard } from '../sections/StalingCard';
import { TopMentors } from '../sections/TopMentors';

const HomePageView = () => (
    <>
        <HomeTopSection />
        <Domains />
        <TopMentors />
        <MentorIcon />
        <Partner />
        <StalingCard />
        <HowItWorksStepper />
        <LinearImages />
        {/* <Member /> */}
        {/* <LinearMentor /> */}
    </>
);
export default HomePageView;
