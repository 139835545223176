import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    createApi,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { IGenericError, OriginalRequest, RefreshTokenResponse } from '@data-types/generic';
import { authSuccess, setLogout } from '@modules/auth/lib/authSlice';
// eslint-disable-next-line import/no-cycle
import { RootState, store } from '@store/store';
import ApiResponses from '@utils/api';

// Define a service using a base URL and expected endpoints
export const baseQuery = fetchBaseQuery({
    baseUrl: '/api/v1',
    prepareHeaders: (headers, { getState, endpoint }) => {
        const { accessToken } = (getState() as RootState).reducer.auth;
        if (accessToken && endpoint !== 'refresh') {
            headers.set('Authorization', `Bearer ${accessToken}`);
        }
        return headers;
    },
    credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError | string
> = async (args, api, extraOptions) => {
    const { accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn } = (
        store.getState() as RootState
    ).reducer.auth;
    const currentDateAndTime = new Date().getTime() / 1000;

    if (
        refreshToken &&
        accessTokenExpiresIn < currentDateAndTime &&
        refreshTokenExpiresIn > currentDateAndTime
    ) {
        try {
            const refreshResult = await baseQuery(
                {
                    url: '/auth/refresh-token',
                    method: 'GET',
                    headers: { Authorization: `Bearer ${refreshToken}` },
                },
                { ...api, endpoint: 'refresh' },
                extraOptions
            );
            const data = refreshResult.data as RefreshTokenResponse;
            if (data) {
                store.dispatch(
                    authSuccess({
                        accessToken: data.accessToken,
                        refreshToken: data.refreshToken,
                        accessTokenExpiresIn: data.accessTokenExpiresIn,
                        refreshTokenExpiresIn: data.refreshTokenExpiresIn,
                    })
                );
                const result = await originalRequest({ args, api, extraOptions });
                return result;
            }
        } catch (error) {
            store.dispatch(setLogout());
            return { error: '' };
        }
    }

    const result = await originalRequest({ args, api, extraOptions });
    return result;
};

const originalRequest = async ({ args, api, extraOptions }: OriginalRequest) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401 || result.error?.status === 403) {
        store.dispatch(setLogout());
        return { error: '' };
    }
    if (result.error) {
        const error: IGenericError = ApiResponses.handleError(result.error);
        return { error: error.message };
    }
    return { data: result.data };
};

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Education',
        'Experience',
        'Personal',
        'Achievement',
        'AdditionalExperience',
        'Availability',
        'Blackout',
        'Mentors',
        'Plans',
        'MenteePlans',
        'PayoutMethods',
        'Settings',
        'Sessions',
        'Notifications',
    ],
    endpoints: () => ({}),
});
