import React from 'react';

import { Box, ListItem, UnorderedList, Flex, Stack } from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';

import { categoriesData } from '@utils/data';

const Domains = () => {
    const categories = [<Marquee>{categoriesData}</Marquee>];

    return (
        <Stack py={{ base: 0, md: 16 }} mx={{ base: 0, md: -10, lg: -20 }}>
            <Box bg="black" color="white" fontWeight="bold" gap={4} px={{ base: 4, md: 20 }}>
                <Flex
                    as={UnorderedList}
                    columnGap={10}
                    fontFamily="Fira Sans"
                    alignItems={{ base: 'center', md: 'center' }}
                    overflow={{ base: 'scroll', md: 'hidden' }}
                    py={{ base: 4, md: 6 }}
                >
                    {categories.map((category, index) => (
                        <ListItem zIndex={0} key={index}>
                            {category}
                        </ListItem>
                    ))}
                </Flex>
            </Box>
        </Stack>
    );
};
export default Domains;
