import React from 'react';

import { Button, HStack, Stack } from '@chakra-ui/react';
import Link from 'next/link';

import { useAppDispatch } from '@hooks/store';
import { showToast } from '@slices/apiSlice';

import CustomText from './CustomText';

interface CustomLinkProps {
    label: string;
    url: string;
    showCopyBtn?: boolean;
}

const CustomLink = ({ label, url, showCopyBtn = false }: CustomLinkProps) => {
    const dispatch = useAppDispatch();
    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                dispatch(showToast({ variant: 'success', description: 'Copied' }));
            })
            .catch(err => {
                console.error('Error copying to clipboard:', err);
            });
    };
    return (
        <Stack spacing={0} width="auto">
            <HStack width="100%" justify="space-between" style={{ textWrap: 'balance' }}>
                <CustomText size="md" color="subText">
                    {label}
                </CustomText>
                {showCopyBtn && (
                    <Button
                        size="xs"
                        fontWeight={500}
                        color="primary.light"
                        bgColor="lightBlueBg"
                        variant="ghost"
                        onClick={() => handleCopyToClipboard(url)}
                    >
                        Copy
                    </Button>
                )}
            </HStack>
            {url ? (
                <Link href={url} target="_blank">
                    <CustomText maxWidth={400} color="primary.light" size="md">
                        {url}
                    </CustomText>
                </Link>
            ) : (
                <CustomText maxWidth={400} color="primary.light" size="md">
                    -
                </CustomText>
            )}
        </Stack>
    );
};

export default CustomLink;
