import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ProfilePicture {
    isDeleted: boolean;
}
const initialState: ProfilePicture = {
    isDeleted: false,
};

export const profilePictureDeletion = createSlice({
    name: 'profilePictureDeletion',
    initialState,
    reducers: {
        setDeleteProfilePicture: (state, action: PayloadAction<ProfilePicture>) => {
            state = { ...state, ...action.payload };
            return state;
        },
    },
});

export const { setDeleteProfilePicture } = profilePictureDeletion.actions;

export default profilePictureDeletion.reducer;
