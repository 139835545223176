import React, { JSXElementConstructor, ReactElement } from 'react';

import { Button } from '@chakra-ui/react';

import { CustomText } from '@components/Custom-text';

interface OutlineButtonProps {
    type: 'button' | 'submit' | 'reset' | undefined;
    buttonLabel: string;
    icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
    onClick?: () => void;
    width?: string;
    color?: string;
    borderColor?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
}

const OutlineButton: React.FC<OutlineButtonProps> = ({
    type,
    icon,
    onClick,
    buttonLabel,
    width,
    color = 'primary.light',
    borderColor = 'primary.light',
    isLoading,
    isDisabled,
}) => (
    <Button
        width={width}
        color={color}
        borderColor={borderColor}
        leftIcon={icon}
        type={type}
        variant="outline"
        onClick={onClick}
        isLoading={isLoading}
        isDisabled={isDisabled}
    >
        <CustomText size="md" color={color}>
            {buttonLabel}
        </CustomText>
    </Button>
);

export default OutlineButton;
