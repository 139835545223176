import { ResponsiveValue } from '@chakra-ui/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';

import card from '@assets/card.png';
import laptop from '@assets/laptop.png';
import Mentor from '@assets/mentorimage.png';
import personalData from '@assets/personalData.svg';
import phone from '@assets/phone.png';
import sofa from '@assets/sofa.svg';

dayjs.extend(utc);
dayjs.extend(timezone);

export function changeTimeStringToDateFormat(hour: number) {
    const baseDate = dayjs('2024-01-22').utc().hour(hour).minute(0).second(0).tz('Asia/Kolkata');
    return baseDate.toISOString();
}

export const categoriesData =
    'SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY-SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY-SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY-SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBERSECURITY-SOFTWARE - FINANCE - HR- MARKETING -CONSULTING -CYBER SECURITY';

export const membersNewData = [
    {
        name: 'Floyd Miles',
        text: "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized.",
    },
    {
        name: 'Albert Flores',
        text: "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized.",
    },
    {
        name: 'Ronald Richards',
        text: "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized.",
    },
    {
        name: 'Jenny Wilon',
        text: "I love how user-friendly this app is! It's so easy to add events and set reminders, and it's made my life so much more organized.",
    },
];

export const partnerNewCardData = [
    {
        heading: 'Our Beloved Partners',
        text: 'Lorem ipsum dolor sit amet, consectetur a dipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        backgroundImage: 'rgba(184, 208, 230, 1)',
        imageSrc: sofa,
    },
    {
        heading: 'Our Beloved Partners',
        text: 'Lorem ipsum dolor sit amet, consectetur a dipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        backgroundImage: 'rgba(242, 199, 212, 1)',
        imageSrc: laptop,
    },
    {
        heading: 'Our Beloved Partners',
        text: 'Lorem ipsum dolor sit amet, consectetur a dipiscing elit, sed do eiusmod tempor incididunt ut labore et',
        backgroundImage: 'rgba(178, 160, 222, 1)',
        imageSrc: phone,
    },
];

export const textsData = [
    'Decision-overwhelm and analysis-paralysis',
    'Lack of like-minded peers in your circle',
    'Expensive consultants & coaches',
];
export const newTextsData = [
    'Not enough time to do “all the thing',
    'Too much reliance on trial and error',
    'Content overload & noise',
];
export const newStepsData = [
    {
        stepNumber: '1',
        heading: 'Log in or Register',
        text: "Log in to connect with mentors and fellow learners. Let's learn together!",
        imageSrc: Mentor,
        backgroundImage: '#F2C7D4',
        direction: { base: 'column', md: 'row' },
    },
    {
        stepNumber: '2',
        heading: 'Fill Your personal Data',
        text: "Log in to connect with mentors and fellow learners. Let's learn together!",
        imageSrc: personalData,
        backgroundImage: '#0F98AB',
        direction: { base: 'column', md: 'row-reverse' },
    },
    {
        stepNumber: '3',
        heading: 'Find the Mentor',
        text: "Log in to connect with mentors and fellow learners. Let's learn together!",
        imageSrc: card,
        backgroundImage: '#B2A0DE',
        direction: { base: 'column', md: 'row' },
    },
    {
        stepNumber: '4',
        heading: 'Start the sessions',
        text: "Log in to connect with mentors and fellow learners. Let's learn together!",
        imageSrc: Mentor,
        backgroundImage: '#E5E7EB',
        direction: { base: 'column', md: 'row-reverse' },
    },
] as {
    stepNumber: string;
    heading: string;
    text: string;
    imageSrc: string | StaticImport;
    backgroundImage: string;
    direction: ResponsiveValue<'row' | 'column' | 'row-reverse' | 'column-reverse'> | undefined;
}[];
