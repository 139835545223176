import { Flex, Spinner } from '@chakra-ui/react';

type LoaderProps = {
    height: string;
    width: string;
    size: string;
    position?: 'relative' | 'absolute';
    bgColor?: string;
};
const Loader: React.FC<LoaderProps> = ({
    width,
    height,
    size,
    position = 'relative',
    bgColor,
}: LoaderProps) => (
    <Flex
        bgColor={bgColor}
        justifyContent="center"
        alignItems="center"
        h={height}
        w={width}
        position={position}
        zIndex={1000}
    >
        <Spinner size={size} />
    </Flex>
);

export default Loader;
