import React from 'react';

import { Stack } from '@chakra-ui/react';

import CustomText from './CustomText';

interface TextDetailProps {
    label: string;
    value: string;
}

const TextDetail = ({ label, value }: TextDetailProps) => (
    <Stack spacing={0}>
        <CustomText size="md" color="subText">
            {label}
        </CustomText>
        <CustomText size="md" color="mainText">
            {value ?? '-'}
        </CustomText>
    </Stack>
);

export default TextDetail;
